

































































import wAxios from '@/plugins/w/axios'

const api = {
  examtemplate: (examId) => wAxios.get_auth(`/v1/teacher/examtemplates/${examId}`),
  exam: (examId, testId) => wAxios.get_auth(`/v1/teacher/examtemplates/${examId}/exams/${testId}`),
  students: (examId, testId, params?) => wAxios.get_auth(
    `/v1/teacher/examtemplates/${examId}/exams/${testId}/students`, { params }
  )
}

export default {

  components: {
    'a-header': () => import('@/plugins/app/_layout/a-header.vue'),
    'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue')
  },

  data() {
    return {
      currentPage: +this.$route.query?.page || 1,
      perPage: 8,
      totalStudents: null,
      loading: false,
      examId: this.$route.params.id,
      testId: this.$route.params.testId,
      testName: '',
      students: [],
      studentId: 0,
      questionsLength: 0
    }
  },
  
  async mounted() {

    try {
      this.loading = true
      await Promise.all([
        api.examtemplate(this.examId),
        api.exam(this.examId, this.testId),
        this.loadStudents()
      ]).then(
        ([examtemplate, exam]) => {
          this.questionsLength = examtemplate.data.data.questions.length
          this.testName = exam.data.data.name
        }
      )
    } catch(err) {
      this.$wToast.error(err)
    } finally {
      this.loading = false
    }
  },

  methods: {
    async loadStudents(event) {
      const page = (event != null && event != undefined) ? event : this.currentPage
      const { data } = await api.students(this.examId, this.testId, { page, limit: this.perPage })
      this.currentPage = data.meta?.current_page
      this.totalStudents = data.meta?.total
      this.students = data.data
    }
  }
}
